import {_, React, Component} from 'components';
import Input from '../input/input.js';
import Group from '../../group/group.js';
import SliderInput from '../slider/slider-input.js';
import TimeTextInput from '../time-text/time-text-input.js';
import './time-input.scss';

class TimeInput extends Input {
  get sliderSnapValues() {
    return [
      1, 5, 15, 30, 45, //minutes
      480, 480*2, 480*3, 480*4, //days - 1, 2, 3, 4
      2400, 2400*2, 2400*3, 2400*4, //weeks - 1, 2, 3, 4
      10080, 10080*2, 10080*3, 10080*6, //months, 1, 2, 3, 6
      125280, 125280*2, 125280*3, 125280*5, 125280*10 //years, 1, 2, 3, 5, 10
    ];
  }

  render() {
    return (
      <Group
        className={`form-input time-input${this.props.className ? ` ${this.props.className}` : ''}`}
      >
        {this.props.label ? <label className='label'>{this.props.label}</label> : null}
        <div className='inputs-wrapper'>
          <TimeTextInput
            value={this.props.value}
            onChange={this.handleChange}
          />
          <SliderInput
            value={this.props.value}
            snapValues={this.sliderSnapValues}
            onChange={this.handleChange}
            allowClickToToggle={false}
          />
        </div>
      </Group>
    );
  }
}

export default TimeInput;
