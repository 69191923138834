import React from 'react';
import createMentionPlugin, {defaultSuggestionsFilter} from 'draft-js-mention-plugin';
import createToolbarPlugin from 'draft-js-static-toolbar-plugin';
import {EditorState, convertFromHTML, ContentState, AtomicBlockUtils, convertFromRaw, convertToRaw} from 'draft-js';
import Input from 'components/form/inputs/input/input.js';
import {stateToHTML as editorStateToHTML} from 'draft-js-export-html';
import Editor from 'draft-js-plugins-editor';
import {
  ItalicButton, BoldButton, UnderlineButton, CodeButton, HeadlineOneButton, HeadlineTwoButton, HeadlineThreeButton,
  UnorderedListButton, OrderedListButton, BlockquoteButton, CodeBlockButton
} from 'draft-js-buttons';
import _ from 'lodash';

import './rich-text-input.scss';

const convertUserHandle = ({value, mode, options}) => {
    var regex = /([@][\w]+)/g;
    var convertedValue = value;
    var matches = [...value.matchAll(regex)];

    if (matches.length > 0) {
      _.forEach(matches, match => {
        var user = _.find(options, option => {
          return _.includes(_.lowerCase(option.handle), _.lowerCase(match[0])) || _.includes(_.lowerCase(option.value), _.lowerCase(match[0]));
        });

        if (user) convertedValue = mode === 'idToHandle' ? _.replace(convertedValue, user.value, user.handle) : _.replace(convertedValue, user.handle, user.value);
      });
    }

    return convertedValue;
  };

// this is to recreate mentions, if any, from the html that we are receiving as props
const formatMentionsForEditorState = ({editorState, options}) => {
    // find the @user
    var regex = /([@][\w]+)/g;

    // convert current editorState to object. {blocks: [], entityMap: {}}
    // blocks are basically the lines/paragraphs and entityMap in this case contains Mentions information
    // each block has some property, in case of Mentions, it has the range of each Mentions and styles
    var rawEditorBlocks = convertToRaw(editorState.getCurrentContent()).blocks;
    var editorBlocks = [];
    var entityMaps = [];

    // loop thorough each block
    _.forEach(rawEditorBlocks, rawEditorBlock => {
      // convert userID to userHandle
      var text = convertUserHandle({value: rawEditorBlock.text, mode: 'idToHandle', options});
      var matches = [...text.matchAll(regex)];

      if (matches.length > 0) {
        var entityRanges = [];
        var createdBlockForMentions = [];
        // get the range of each matched Mention
        _.forEach(matches, (match, index) => {
          var user = _.find(options, option => option.value === match[0] || option.handle === match[0]);
          var mention = {handle: user.handle, ...match};
          var offset = mention.index;
          var length = mention.handle.length;
          var mentionData = _.find(options, m => m.handle === mention.handle);

          entityRanges.push({offset, length, key: index});

          // get the current content
          var content = editorState.getCurrentContent();
          // create mention for each matched mention
          var contentStateWithMention = content.createEntity('mention', 'SEGMENTED', {mention: mentionData});
          var mentionKey = contentStateWithMention.getLastCreatedEntityKey();
          // this is the way that I found to add created mention to the block - for some reason it creates
          // three blocks, so later I need to grab the second one that actually has all the information
          var editorStateWithMention = AtomicBlockUtils.insertAtomicBlock(EditorState.createEmpty(), mentionKey, `${user.handle}`);
          // convert the newly created editorState with mention to an object to manipulate and add range and ...
          var rawEditorStateWithMention = convertToRaw(editorStateWithMention.getCurrentContent());
          var createdBlockForMention = {
            ...rawEditorStateWithMention.blocks[1],
            ..._.pick(['type', 'inlineStyleRanges'], rawEditorBlock),
            text,
            entityRanges
          };

          entityMaps.push(...Object.values(rawEditorStateWithMention.entityMap));
          createdBlockForMentions.push(createdBlockForMention);
        });

        // if there are more than matched mentions in a single line, I just need one block
        editorBlocks.push(_.last(createdBlockForMentions));
      }
      else {
        // blocks with no mention
        editorBlocks.push(rawEditorBlock);
      }
    });


    var modifiedContentState = {blocks: editorBlocks, entityMap: {...entityMaps}};
    var contentFromRaw = convertFromRaw(modifiedContentState);

    return EditorState.createWithContent(contentFromRaw);
  };

class RichTextInput extends Input {
  constructor(props) {
    super(props);

    this.state = {
      editorState: EditorState.createEmpty(),
      isFocused: false,
      prevProps: {},
      filteredMentionSuggestions: []
    };

    this.mentionPlugin = createMentionPlugin({mentionPrefix: '@'});
    this.toolbarPlugin = createToolbarPlugin();

    this.handleEditorChange = this.handleEditorChange.bind(this);
    this.handleMentionSearch = this.handleMentionSearch.bind(this);
    this.focus = this.focus.bind(this);
  }

  async handleEditorChange(editorState) {
    var value = editorStateToHTML(editorState.getCurrentContent());

    var convertedValue = convertUserHandle({value, mode: 'handleToId', options: _.get(this, 'props.mentions.users.options')});

    this.setState({editorState, value: convertedValue});

    this.handleChange({value: convertedValue});
  }

  handleMentionSearch({value}) {
    var {mentions} = this.props;

    this.setState({filteredMentionSuggestions: defaultSuggestionsFilter(value, mentions.users.options)});
  }

  onAddMention() {

  }

  focus() {
    this.editor.focus();
  }

  static getDerivedStateFromProps(props, state) {
    var prevProps = state.prevProps || {};
    var value = prevProps.value !== props.value ? props.value : state.value;
    var editorState = state.editorState;

    if (value !== state.value || !state.formattedMentionsOnLoad) {
      var blocksFromHTML = convertFromHTML(value);
      var contentState = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);

      editorState = formatMentionsForEditorState({editorState: EditorState.createWithContent(contentState), options: _.get(props, 'mentions.users.options')});
    }

    return {value, editorState, formattedMentionsOnLoad: true, prevProps: props};
  }

  render() {
    var {MentionSuggestions} = this.mentionPlugin;
    var {Toolbar} = this.toolbarPlugin;
    var plugins = [this.mentionPlugin, this.toolbarPlugin];

    return (
      <div
        className='editor'
        onClick={this.focus}
        onFocus={() => this.setState({isFocused: true})}
        onBlur={() => this.setState({isFocused: false})}
        ref={(element) => this.editorContainer = element}
      >
        {(this.state.isFocused || this.props.fixedToolbar) &&
          <Toolbar>
            {(providedProps) => (
              <>
                <BoldButton {...providedProps}/>
                <ItalicButton {...providedProps} />
                <UnderlineButton {...providedProps} />
                <CodeButton {...providedProps} />
                <HeadlineOneButton {...providedProps}/>
                <HeadlineTwoButton {...providedProps}/>
                <HeadlineThreeButton {...providedProps}/>
                <UnorderedListButton {...providedProps} />
                <OrderedListButton {...providedProps} />
                <BlockquoteButton {...providedProps} />
                <CodeBlockButton {...providedProps} />
              </>
            )}
          </Toolbar>
        }
        <Editor
          editorState={this.state.editorState}
          onChange={this.handleEditorChange}
          plugins={plugins}
          ref={(element) => this.editor = element}
        />
        <MentionSuggestions
          onSearchChange={this.handleMentionSearch}
          suggestions={this.state.filteredMentionSuggestions || this.props.mentions.users.options}
          onAddMention={this.onAddMention}
        />
      </div>
    );
  }
}

export default RichTextInput;
