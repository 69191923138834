import {_, React, Component} from 'components';
import {Input as SemanticTextInput, TextArea as SemanticTextArea, Label} from 'semantic-ui-react';
import TextareaAutosize from 'react-autosize-textarea';
import Input from 'components/form/inputs/input/input.js';
import ReactDOM from 'react-dom'

import './text-input.scss';

//wrapped to fix react's weird change/input behavior
class WrappedSemanticInput extends Component {
  constructor(props) {
    super(props);

    this.state = {...this.state, value: '', prevProps: {}};

    this.bind(['handleChange', 'handleBlur', 'handleKeyDown', 'getRef']);

    if (this.props.changeOnInput) {
      this.debouncedOnChange = _.debounce(this.onChange.bind(this), this.props.changeOnInputDelay || 500);
    }
  }

  onChange() {
    this.props.onChange({value: this.state.value});
  }

  handleChange(event) {
    var {value} = event.target;

    this.setState({value}, () => {
      //HINT trigger a change event if implementation calls for save after a delay
      if (this.props.changeOnInput) this.debouncedOnChange();
    });

    this.props.onInput({value});
  }

  handleKeyDown(event) {
    var {value} = event.target;

    if (event.keyCode === 13) {
      var changeOnEnter = !this.props.isMultiline || this.props.changeOnEnter;
      var {preventNewLines} = this.props;

      if (changeOnEnter || preventNewLines) event.preventDefault();
      if (changeOnEnter) event.target.blur(); //HINT triggers change
    }

    this.props.onKeyDown({value, event});
  }

  handleBlur() {
    this.props.onChange({value: this.state.value});
    this.props.onBlur({value: this.state.value});
  }

  getRef(ref) {
    if (ref) {
      ref = ReactDOM.findDOMNode(ref);

      if (!_.includes(['TEXTAREA', 'INPUT'], ref.tagName)) {
        ref = ref.querySelector(this.props.isMultiline ? 'textarea' : 'input');
      }

      this.ref = ref;

      if (this.props.getInputRef) this.props.getInputRef(ref);
    }
  }

  render() {
    var className = `form-input text-input`;

    if (this.className) className += ` ${this.className}`;
    if (this.props.className) className += ` ${this.props.className}`;
    if (!this.props.isValid) className += ` is-invalid`;

    var props = {
      value: this.state.value,
      onChange: this.handleChange,
      onKeyDown: this.handleKeyDown,
      onBlur: this.handleBlur,
      ref: this.getRef,
      ..._.pick(this.props, ['name', 'label', 'placeholder', 'disabled'])
    };

    if (this.props.isMultiline && !this.props.autosize) props.rows = this.props.rows || 1;
    if (this.props.isPassword) props.type = 'password';

    var TextareaInput = this.props.autosize ? TextareaAutosize : SemanticTextArea;

    return this.props.isMultiline ? (this.props.label ? (
        <div className={className}>
          <Label>{this.props.label}</Label>
          <TextareaInput className={className} {...props}/>
        </div>
      ) : (
        <TextareaInput className={className} {...props}/>
      )
    ) : (
      <SemanticTextInput className={className} {...props}/>
    );
  }

  static getDerivedStateFromProps(props, state) {
    var prevProps = state.prevProps || {};
    var value = prevProps.value !== props.value ? props.value : state.value;

    return {value, prevProps: props};
  }
}

class TextInput extends Input {
  constrain(value) {
    if (typeof(value) === 'number') {
      value = `${value}`;
    }
    else if (typeof(value) !== 'string') {
      value = '';
    }

    //TODO figure out how to make this happen on input
    if (!this.props.isMultiline || this.props.preventNewLines) {
      value = value.replace(/\n/g, '');
    }

    return value;
  }

  render() {
    return <WrappedSemanticInput
      onChange={this.handleChange}
      onInput={this.handleInput}
      onBlur={({value}) => this.trigger('blur', {value})}
      onKeyDown={({value, event}) => this.trigger('keyDown', {value, event})}
      value={this.displayValue}
      key={this.state.forceUpdateKey}
      {..._.pick(this.props, [
        'name', 'label', 'placeholder', 'className',
        'isMultiline', 'autosize', 'isPassword',
        'changeOnInput', 'changeOnInputDelay', 'changeOnEnter',
        'getInputRef', 'rows', 'disabled'
      ])}
      {..._.pick(this.state, ['isValid'])}
    />;
  }
}

export default TextInput;
