import {_, React, className} from 'components';
import Input from '../input/input.js';
import './slider-input.scss';

class SliderInput extends Input {
  constructor(props) {
    super(props);

    this.state = {isSliding: false, prevProps: {}, value: this.props.value};

    this.handleEvent = this.handleEvent.bind(this);
  }

  componentDidMount() {
    window.addEventListener('mouseup', () => this.setState({isSliding: false}), false);
  }

  componentWillUnmount() {
    window.removeEventListener('mouseup', () => this.setState({isSliding: false}), false);
  }

  handleEvent(event) {
    var value;
    var {snapValues} = this.props;
    var valueBoxWidth = event.clientX - this.valueBox.getBoundingClientRect().left;
    var valueBoxMaxWidth = this.valueBox.parentElement.getBoundingClientRect().width;
    var valueProportion = valueBoxWidth / valueBoxMaxWidth;

    if (snapValues) {
      var snapValuesLength = valueBoxMaxWidth / snapValues.length;
      var index = valueBoxWidth / snapValuesLength;
      var snapValueIndex = index < 0 ? 0 : Math.floor(index);

      value = snapValues[snapValueIndex];
    }
    else {
      value = valueProportion * (this.maxValue - this.minValue);
    }

    if (event.type === 'mousedown') {
      this.setState({mouseDownX: event.clientX, isSliding: true, slidingValue: value, value});
    }
    else if (event.type === 'mousemove' && this.state.isSliding) {
      this.setState({slidingValue: value, value});
    }
    else if (event.type === 'mouseup') {
      //HINT toggle between min/max values if user just clicks - if in middle, set to min
      if (this.props.allowClickToToggle && event.clientX === this.state.mouseDownX) {
        value = value === this.props.minValue ? this.props.minValue : this.props.maxValue;
      }

      this.handleChange({value});

      this.setState({isSliding: false});
    }
  }

  get minValue() {
    return this.props.snapValues ? _.min(this.props.snapValues) : this.props.minValue;
  }

  get maxValue() {
    return this.props.snapValues ? _.max(this.props.snapValues) : this.props.maxValue;
  }

  render() {
    var value = this.state.isSliding ? this.state.slidingValue : this.state.value;
    var valueBoxWidth;
    var {snapValues} = this.props;

    if (value === 0) {
      valueBoxWidth = 0;
    }
    else if (snapValues) {
      value = _.indexOf(snapValues, value) === -1 ? _.find(snapValues, snapValue => snapValue > value) : value;

      valueBoxWidth = 100 / (snapValues.length / (_.indexOf(snapValues, value) + 1));
    }
    else {
      valueBoxWidth = ((value - this.minValue) / (this.maxValue - this.minValue)) * 100;
    }

    return (
      <div
        {...className(['slider-input'])}
        onMouseDown={this.handleEvent}
        onMouseMove={this.handleEvent}
        onMouseUp={this.handleEvent}
      >
        <div
          className='si-value-box'
          ref={(valueBox) => this.valueBox = valueBox}
          style={{width: `${valueBoxWidth}%`}}
        ></div>
      </div>
    );
  }

  static getDerivedStateFromProps(props, state) {
    var prevProps = state.prevProps || {};
    var value = prevProps.value !== props.value ? props.value : state.value;

    return {value, prevProps: props};
  }
}

export default SliderInput;
