import PropTypes from 'prop-types';
import _ from 'lodash';

export default function withEvents(Target) {
  var forEachEventKey = (eventKeys, predicate) => {
    _.forEach(_.split(eventKeys, ' '), predicate);
  };

  class ClassWithEvents extends Target {
    static propTypes = {
      on: PropTypes.shape({

      })
    }

    static defaultProps = {
      on: {}
    }

    constructor(props) {
      super(props);

      this._eventData = {};
      this._eventHandlers = {};

      _.forEach(this.props.on, (handler, eventKeys) => this.on(eventKeys, handler));
    }

    trigger(eventKeys, data) {
      forEachEventKey(eventKeys, eventKey => {
        var eventData = {...this._eventData[eventKey] || {}, ...data};
        var onPropKey = _.camelCase(`on-${_.kebabCase(eventKey)}`);

        if (this.props[onPropKey]) this.props[onPropKey](eventData); //onChange={() => }

        _.forEach(this._eventHandlers[eventKey], handler => handler(eventData)); //on={{change: () => }}

        delete this._eventData[eventKey];
      });
    }

    on(eventKeys, handler) {
      forEachEventKey(eventKeys, eventKey => {
        this._eventHandlers[eventKey] = this._eventHandlers[eventKey] || [];

        this._eventHandlers[eventKey].push(handler);
      });
    }

    off(eventKeys, handler) {
      forEachEventKey(eventKeys, eventKey => {
        if (this._eventHandlers[eventKey]) {
          if (handler) {
            _.pull(this._eventHandlers[eventKey], handler);
          }
          else {
            delete this._eventHandlers[eventKey];
          }
        }
      });
    }

    setEventData(eventKeys, data) {
      forEachEventKey(eventKeys, eventKey => {
        this._eventData[eventKey] = this._eventData[eventKey] || {};

        _.extend(this._eventData[eventKey], data);
      });
    }

    registerTriggers(events) {
      _.forEach(events, (fnName, eventKey) => {
        var fn = this[fnName];

        if (fn) {
          fn = fn.bind(this);

          this[fnName] = (function(...args) {
            var result = fn(...args);

            this.trigger(eventKey);

            return result;
          }).bind(this);
        }
      });
    }
  }

  return ClassWithEvents;
}

class ClassWithEvents {
  constructor(props) {
    this.props = props || {};
  }
}

ClassWithEvents = withEvents(ClassWithEvents);

export {ClassWithEvents};
