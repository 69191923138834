import {_, React, Component} from 'components';
import {Form as SemanticForm} from 'semantic-ui-react';

import TextInput from './inputs/text/text-input';
import DollarInput from './inputs/dollar/dollar-input';
import DropdownInput from './inputs/dropdown/dropdown-input';
import RadioInput from './inputs/radio/radio-input';
import DateInput from './inputs/date/date-input';
import SliderInput from './inputs/slider/slider-input';
import TimeTextInput from './inputs/time-text/time-text-input';
import TimeInput from './inputs/time/time-input';
import HiddenInput from './inputs/hidden/hidden-input';
import SubmitButton from './submit-button/submit-button';
import RichTextInput from './inputs/rich-text/rich-text-input';

import './form.scss';

class Form extends Component{
  static defaultProps = {
    useSubmitInput: false
  }

  constructor(props) {
    super(props);

    this.bind(['handleSubmit']);

    this.state = {isSubmitting: false};
  }

  handleSubmit() {
    return new Promise((resolve) => {
      this.setState({isSubmitting: true});

      var onSubmit = _.get(this.props, 'on.submit');

      if (onSubmit) {
        onSubmit()
        .then(() => {
          this.setTimeout(() => this.setState({isSubmitting: false}), 1000);
        })
        .catch((error) => {
          if (process.env.NODE_ENV !== 'test') console.error(error); //eslint-disable-line

          this.setState({isSubmitting: false});
        });
      }
    });

  }

  render() {
    return (
      <SemanticForm {..._.pick(this.props, ['action', 'method', 'encType'])} onSubmit={this.handleSubmit}>
        {this.props.children}
        {this.props.useSubmitInput && (
          <SubmitButton
            isSubmitting={this.state.isSubmitting}
            submitText={this.props.submitText}
            isSubmittingText={this.props.isSubmittingText}
          />
        )}
      </SemanticForm>
    );
  }
}

Form.TextInput = TextInput;
Form.DollarInput = DollarInput;
Form.DropdownInput = DropdownInput;
Form.RadioInput = RadioInput;
Form.DateInput = DateInput;
Form.SliderInput = SliderInput;
Form.TimeInput = TimeInput;
Form.TimeTextInput = TimeTextInput;
Form.HiddenInput = HiddenInput;
Form.SubmitButton = SubmitButton;
Form.RichTextInput = RichTextInput;

export default Form;
