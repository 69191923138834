import {_, Component, PropTypes} from 'components';

export default class Input extends Component {

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleInput = this.handleInput.bind(this);

    this.state = {...this.state, oldValue: props.value, isValid: this.valueIsValid(this.props.value), forceUpdateKey: this.createUuid()};
  }

  componentDidMount() {
    this.clean(this.props.value);
  }

  componentDidUpdate() {
    this.clean(this.props.value);
  }

  valueIsValid() {
    return true;
  }

  constrain(value) {
    if (this.props.constrain) value = this.props.constrain(value);

    return value;
  }

  clean(value, {event}={}) {
    var constrainedValue = this.constrain(value);
    var valueWasConstrained = value != constrainedValue; //HINT intentional use of type unsafe comparison to compare numbers and strings
    var shouldTriggerChange = (this.props.triggerChangeOnClean && !_.isEqual(constrainedValue, this.props.value)) || !_.isEqual(value, this.props.value);

    if (shouldTriggerChange) this.trigger('change', {value: constrainedValue, name: this.props.name, event});
    if (valueWasConstrained) this.trigger('valueConstrained', {value, constrainedValue, name: this.props.name, event});

    if (valueWasConstrained && this.state.oldValue !== undefined && value !== undefined && constrainedValue === this.state.oldValue) {
      this.setState({forceUpdateKey: this.createUuid()});
    }

    return constrainedValue;
  }

  createUuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
      var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  handleChange({event, target, value}) {
    if (target) value = target.value;

    var constrainedValue = this.clean(value, {event});

    this.setState({isValid: this.valueIsValid(constrainedValue.toString()), oldValue: constrainedValue});

    return constrainedValue;
  }

  handleInput({value}) {
    this.trigger('input', {value});

    this.setState({isValid: this.valueIsValid(value)});
  }

  get displayValue() {
    return this.props.value;
  }
}

class InputWithOptions extends Input {
  static propTypes = {
    options: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired
    })).isRequired
  }

  constrain(value) {
    var values = _.map(this.props.options, 'value');

    if (!this.props.multiple && values.length > 0 && !_.includes(values, value)) value = values[0];

    return value;
  }
}

export {Input, InputWithOptions};
