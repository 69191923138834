import {React} from 'components';
import DatePicker from "react-datepicker";
import Group from '../../group/group.js';
import Input from '../input/input.js';

import './date-input.scss';

class DateInput extends Input {
  render() {
    return (
      <Group className={`form-input date-input${this.props.className ? ` ${this.props.className}` : ''}`}>
        {this.props.label ? <label className='label'>{this.props.label}</label> : null}
        <DatePicker
          selected={this.props.value}
          onChange={(value) => this.handleChange({value: value ? value : ''})}
          placeholderText={'mm/dd/yyyy'}
        />
      </Group>

    );
  }
}

export default DateInput;
