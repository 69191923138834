import {Component} from 'react';
import _ from 'lodash';
import withEvents from './with-events/with-events.js';

var asComponent = (Target) => {
  class Component extends withEvents(Target) {
    domEventListeners = [];
    refs = {};
    intervals = [];

    bind(fnNames) {
      if (typeof(fnNames) === 'string') fnNames = [fnNames];

      _.forEach(fnNames, fnName => this[fnName] = this[fnName].bind(this));
    }

    addDomEventListener({to, on, handler}) {
      if (typeof(handler) === 'string') handler = this[handler].bind(this);

      this.domEventListeners.push({target: to, eventKey: on, handler});
    }

    componentDidMount() {
      _.forEach(this.domEventListeners, ({target, eventKey, handler}) => {
        target.addEventListener(eventKey, handler);
      });
    }

    componentWillUnmount() {
      _.forEach(this.domEventListeners, ({target, eventKey, handler}) => {
        target.removeEventListener(eventKey, handler);
      });

      this.clearIntervals();
    }

    clearIntervals() {
      _.forEach(this.intervals, clearInterval);

      this.intervals = [];
    }

    setTimeout(fn, time) {
      this.intervals.push(setTimeout(fn, time));
    }

    setInterval(fn, time) {
      this.intervals.push(setInterval(fn, time));
    }

    propsFor({eventKeys}) {
      var props = {};

     _.forEach(eventKeys, eventKey => {
       var propKey = _.camelCase(`on-${_.kebabCase(eventKey)}`);

       props[propKey] = (event) => this.trigger(eventKey, event);
     });

     return props;
   }
  }

  return Component;
};

export default asComponent(Component);

export {withEvents, asComponent};
