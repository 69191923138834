import {Component} from 'components';
import {Form as SemanticForm} from 'semantic-ui-react';

class Group extends Component{

}

Group = SemanticForm.Group;

export default Group;
