import {_} from 'components';
import TextInput from '../text/text-input';
import {time} from '@symbolic/lib';
import './time-text-input.scss';

export default class TimeTextInput extends TextInput {
  constrain(value) {
    var valueInMinutes = 0;

    if (typeof(value) === 'number') {
      valueInMinutes = value;
    }
    else if (typeof(value) === 'string') {
      valueInMinutes = time.toMinutes(value);
    }

    return _.max([this.props.minValue || 0, _.min([valueInMinutes, this.props.maxValue || 1252800])]); //HINT 1m - 10y
  }

  valueIsValid(value) {
    if (typeof(value) === 'number') value = `${value}m`;

    var valueNumber = parseFloat(value);
    var minutes = time.toMinutes(value);

    return !isNaN(valueNumber) && !(minutes < (this.props.minSizeInMinutes || 0));
  }

  get displayValue() {
    return time.toLabel(this.props.value);
  }

  get className() {
    return 'time-text-input';
  }
}
