import {_, React, Component} from 'components'; //eslint-disable-line
import {Button as SemanticButton} from 'semantic-ui-react';

var SubmitButton = ({isSubmitting, isSubmittingText, submitText}) => {
  return (
    <SemanticButton className='submit-input submit-button' type='submit'>
      {isSubmitting ? isSubmittingText || 'Submitting...' : submitText || 'Submit'}
    </SemanticButton>
  );
};

export default SubmitButton;
