import TextInput from '../text/text-input';
import dinero from 'dinero.js';

//this.props.value should always be an amount in cents
export default class DollarInput extends TextInput {
  constrain(value) {
    var valueInCents = 0;

    if (typeof(value) === 'number') {
      valueInCents = value;
    }
    else if (typeof(value) === 'string') {
      var scalar = value.indexOf('-') === -1 ? 1 : -1;
      var valueInDollarsString = value.replace(/[^\d.]+/g, ''); //HINT only keep numbers and "."
      var valueInDollars = parseFloat(valueInDollarsString);

      valueInCents = scalar * (Math.round(valueInDollars * 100) || 0);
    }

    if (!this.props.negativeIsValid) value = Math.abs(value);

    return valueInCents;
  }

  valueIsValid(value) {
    if (value === '') {
      var valueInCents = 0;
    }
    else {
      if (typeof(value) !== 'string') value = value.toString();

      var valueInCents = value.replace(/[\$\,]/g, ''); //HINT allow "$" and ","
    }

    var isNotNumber = isNaN(valueInCents);
    var isInvalidZero = this.props.zeroIsInvalid && parseFloat(valueInCents) === 0;
    var isInvalidNegative = !this.props.negativeIsValid && parseFloat(valueInCents) < 0;
    var isInvalid = isNotNumber || isInvalidZero || isInvalidNegative;

    return !isInvalid;
  }

  get displayValue() {
    var valueInCents = this.props.value;

    return valueInCents === 0 ? '' : dinero({amount: valueInCents}).toFormat('0.00');
  }
}
